<template>
  <router-view />
</template>
<script lang="ts">
import EventBus from "./common/EventBus";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  created() {
    document.title = "Solana Melius";
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
    },
  },
  mounted() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    EventBus.on("listenForDarkMode", (data: any) => {
      if (data === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    });
  },
  beforeUnmount() {
    EventBus.remove("listenForDarkMode", null);
  },
};
</script>
